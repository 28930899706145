import {
  ConfirmationModal,
  ControlledForm,
  ErrorPage,
  Icon,
  IconButton,
  InfoCardInner,
  Loading,
  LoadingPage,
  Modal,
  readableDate,
  TConfirmationModalProps,
  TInfoCardSectionColumnProps,
  TInfoCardSectionProps,
  Tooltip,
  useAuthContext,
} from '@chocolate-soup-inc/cs-frontend-components';
import { joiResolver } from '@hookform/resolvers/joi';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { serializeError } from 'serialize-error';
import { CountryName } from '../../../components/coutryName/CountryName';
import { getReadableWorkAnniversaryVariation, getWorkAnniversaryIcon } from '../../../entities/companies/shared';
import { getReadableRelationshipType } from '../../../entities/dependants/shared';
import { getUpdateGiftExtraAttributesSchema } from '../../../entities/gifts/schema';
import {
  getReadableGiftType,
  getRemovedInfoFromRecipientData,
  isDonationGift,
  printGiftLabels,
} from '../../../entities/gifts/shared';
import { isDependant } from '../../../entities/recipients/shared';
import {
  TDependantType,
  TGiftFieldsFragment,
  TGiftStatuses,
  TGiftTypes,
  TWorkAnniversaryGiftVariations,
  useDeleteGiftMutation,
  useMoveGiftMutation,
  useRemoveGiftFromGroupMutation,
  useUpdateGiftMutation,
} from '../../../generated/graphql';
import { COMPANY_PATH, GIFT_PATH } from '../../../routes/paths';
import { MovingToDoneModalContent } from '../GiftAssembling/MovingToDoneModalContent';
import { newHireGiftsAssemblingColumnsDetails } from '../NewHireGiftsAssembling/NewHireGiftsAssembling';
import { subscriptionGiftsAssemblingColumnsDetails } from '../SubscriptionGiftsAssembling/SubscriptionGiftsAssembling';

import styles from './GiftDetails.module.scss';
import {
  TGiftType,
  TGroupedGiftType,
  useFragmentOrFetchGift,
  useQueryAllGroupedAssemblingGifts,
  useQueryAllNewHireAssemblingGifts,
  useQueryAllSubscriptionAssemblingGifts,
} from '../../../entities/gifts/queries';
import { getReadableAddressProperty, getReadableDeliveryMethodType } from '../../../entities/addresses/shared';
import SplitButton from '../../../components/splitButton/SplitButton';
import { useFilter } from '../../../contexts/filters';
import { AlertTooltipDismiss } from '../../../components/alerts/AlertTooltipDismiss';

type TGiftExtraAttributesForm = Pick<TGiftType<TGiftFieldsFragment>, 'rack' | 'notes'>;

export const GiftDetailsInner = (gift: TGiftType<TGiftFieldsFragment>) => {
  const { filtersModalMode } = useFilter();
  useEffect(() => {
    filtersModalMode();
  }, []); // eslint-disable-line

  const [moveLoading, setMoveLoading] = useState<boolean>();
  const [deleteLoading, setDeleteLoading] = useState<boolean>();
  const [confirmationProps, setConfirmationProps] =
    useState<Omit<TConfirmationModalProps, 'closeModal' | 'confirmLoading' | 'onCancelClick'>>();
  const [moveTo, setMoveTo] = useState<TGiftStatuses>();
  const [giftsGoingToDone, setGiftsGoingToDone] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { user: { email: userEmail } = {} } = useAuthContext();
  const [updateGift] = useUpdateGiftMutation();

  const { data: subscriptionGifts } = useQueryAllSubscriptionAssemblingGifts();
  const { data: newHireGifts } = useQueryAllNewHireAssemblingGifts();

  const columnsDetails = useMemo(() => {
    if (gift.type !== TGiftTypes.NewHire) {
      return subscriptionGiftsAssemblingColumnsDetails;
    } else {
      return newHireGiftsAssemblingColumnsDetails;
    }
  }, [gift.type]);

  const giftColumn = useMemo(() => {
    return columnsDetails.find((c) => c.id === gift.status);
  }, [columnsDetails, gift.status]);

  const possibleMoveToColumns = useMemo(() => {
    if (giftColumn?.destinationColumns != null) {
      return columnsDetails.filter((c) => giftColumn?.destinationColumns?.includes(c.id));
    } else {
      return [];
    }
  }, [columnsDetails, giftColumn?.destinationColumns]);

  const nextGift = useMemo(() => {
    let sortedAndFilteredGifts: TGroupedGiftType[];
    if (gift.type === TGiftTypes.NewHire) {
      sortedAndFilteredGifts = newHireGifts.filter((g) => g.type === TGiftTypes.NewHire && g.status === gift.status);
      // sortedAndFilteredGifts = newHireGifts
      //   .filter((g) => g.type === TGiftTypes.NewHire && g.status === gift.status)
      //   .sort(sortGifts);
    } else {
      sortedAndFilteredGifts = subscriptionGifts.filter(
        (g) => g.type !== TGiftTypes.NewHire && g.status === gift.status,
      );
      // sortedAndFilteredGifts = subscriptionGifts
      //   .filter((g) => g.type !== TGiftTypes.NewHire && g.status === gift.status)
      //   .sort(sortGifts);
    }

    const index = sortedAndFilteredGifts.findIndex((g) => g.id === gift.id);
    if (index > -1) {
      return sortedAndFilteredGifts[index + 1];
    } else {
      return undefined;
    }
  }, [gift.id, gift.status, gift.type, newHireGifts, subscriptionGifts]);

  const [moveGift] = useMoveGiftMutation();

  const closeModal = useCallback(() => {
    navigate(generatePath('..'));
  }, [navigate]);

  const actuallyMoveGift = useCallback(() => {
    if (moveTo) {
      if (moveTo === TGiftStatuses.Done) {
        if (gift.address == null) {
          toast.error('You cannot move a gift with no address to done.');
        } else {
          setGiftsGoingToDone(true);
        }
      } else {
        setMoveLoading(true);
        moveGift({
          variables: {
            id: gift.id,
            companyId: gift.companyId,
            version: gift._version,
            input: {
              status: moveTo,
            },
          },
        })
          .then(() => {
            if (nextGift) {
              toast.success(`Gift successfully moved to ${moveTo}. Showing next gift on ${nextGift.status} column.`);

              navigate(
                generatePath(`${location.pathname.split('/').slice(0, -2).join('/')}/${COMPANY_PATH}/${GIFT_PATH}`, {
                  companyId: nextGift.companyId,
                  giftId: nextGift.id,
                }),
              );
            } else {
              closeModal();
            }
          })
          .catch((error) => {
            toast.error(serializeError(error));
          })
          .then(() => {
            setMoveLoading(false);
          });
      }
    }
  }, [
    closeModal,
    gift._version,
    gift.address,
    gift.companyId,
    gift.id,
    location.pathname,
    moveGift,
    moveTo,
    navigate,
    nextGift,
  ]);

  const [deleteGift] = useDeleteGiftMutation();

  const onActualDelete = useCallback(() => {
    setDeleteLoading(true);
    deleteGift({
      variables: {
        id: gift.id,
        companyId: gift.companyId,
        version: gift._version,
      },
    })
      .then(() => {
        closeModal();
      })
      .catch((error) => {
        console.error(serializeError(error));
        toast.error('There was an error deleting the gift.');
      })
      .then(() => {
        setDeleteLoading(false);
        setConfirmationProps(undefined);
      });
  }, [closeModal, deleteGift, gift._version, gift.companyId, gift.id]);

  const onDeleteClick = useCallback(() => {
    setConfirmationProps({
      headline: 'Delete gift?',
      supportingText: `Are you sure you want to delete this gift to ${gift.recipient?.fullName} from company ${gift.company?.name}?`,
      confirmLabel: 'Delete',
      onConfirmClick: onActualDelete,
    });
  }, [gift.company?.name, gift.recipient?.fullName, onActualDelete]);

  const [removeGiftFromGroup, { loading: removeGiftFromGroupLoading }] = useRemoveGiftFromGroupMutation();

  const onActualRemoveFromGroup = useCallback(() => {
    removeGiftFromGroup({
      variables: {
        id: gift.id,
        companyId: gift.companyId,
        version: gift._version,
      },
    })
      .then(() => {
        closeModal();
      })
      .catch((error) => {
        console.error(serializeError(error));
        toast.error('There was an error removing the gift from the group');
      })
      .then(() => {
        setConfirmationProps(undefined);
      });
  }, [closeModal, gift._version, gift.companyId, gift.id, removeGiftFromGroup]);

  const onRemoveFromGroupClick = useCallback(() => {
    setConfirmationProps({
      headline: 'Remove gift from group?',
      supportingText: `Are you sure you want to remove this gift to ${gift.recipient?.fullName} from its group?`,
      confirmLabel: 'Remove from group',
      onConfirmClick: onActualRemoveFromGroup,
    });
  }, [onActualRemoveFromGroup, gift.recipient?.fullName]);

  const isDonation = useMemo(() => {
    return isDonationGift({
      gift,
    });
  }, [gift]);

  const missingAddress = useMemo(() => {
    return gift.addressId == null;
  }, [gift.addressId]);

  const [printLoading, setPrintLoading] = useState<boolean>();

  const onPrintClick = useCallback(async () => {
    setPrintLoading(true);

    try {
      if (gift) {
        await printGiftLabels({
          gifts: [gift],
        });
      }
    } catch (error) {
      console.error(serializeError(error));
      toast.error('There was an error trying to print labels.');
    } finally {
      setPrintLoading(false);
    }
  }, [gift]);

  const topComponent = useMemo(() => {
    return (
      <div className={styles.topComponent}>
        <div className={styles.topTitleContainer}>
          <h2 className={styles.topTitle}>Gift</h2>
          <div className={styles.topIcons}>
            {gift.recreatedFromId && (
              <Tooltip message='This gift was recreated from a shipment.'>
                <Icon icon='dynamic_feed' />
              </Tooltip>
            )}
            {gift.type === TGiftTypes.WorkAnniversary &&
              gift.company?.workAnniversaryGiftVariation != null &&
              gift.company.workAnniversaryGiftVariation !== TWorkAnniversaryGiftVariations.Deluxe && (
                <Tooltip
                  message={`Work Anniversary Variation: ${getReadableWorkAnniversaryVariation(
                    gift.company.workAnniversaryGiftVariation,
                  )}`}
                >
                  {getWorkAnniversaryIcon(gift.company.workAnniversaryGiftVariation)}
                </Tooltip>
              )}
            {isDonation && (
              <Tooltip message='Donation Enabled'>
                <Icon icon='volunteer_activism' />
              </Tooltip>
            )}
            {missingAddress && (
              <Tooltip message='Missing address.'>
                <Icon icon='not_listed_location' />
              </Tooltip>
            )}
            <AlertTooltipDismiss entity={gift} userEmail={userEmail} updateMutation={updateGift} />
          </div>
        </div>
        <div className={styles.topActions}>
          <Tooltip message='Print Gift label'>
            <IconButton
              icon='print'
              onClick={onPrintClick}
              disabled={printLoading || removeGiftFromGroupLoading || deleteLoading}
              variant='outlined'
            />
          </Tooltip>
          {gift.group != null && (
            <Tooltip message='Remove gift from group'>
              <IconButton
                icon='group_off'
                onClick={onRemoveFromGroupClick}
                disabled={printLoading || removeGiftFromGroupLoading || deleteLoading}
                variant='outlined'
              />
            </Tooltip>
          )}
          <Tooltip message='Delete gift'>
            <IconButton
              icon='delete'
              onClick={onDeleteClick}
              disabled={printLoading || removeGiftFromGroupLoading || deleteLoading}
              variant='outlined'
            />
          </Tooltip>
        </div>
      </div>
    );
  }, [
    gift,
    isDonation,
    missingAddress,
    onPrintClick,
    printLoading,
    removeGiftFromGroupLoading,
    deleteLoading,
    onRemoveFromGroupClick,
    onDeleteClick,
    userEmail,
    updateGift,
  ]);

  const sections = useMemo(() => {
    const secs: TInfoCardSectionProps<TGiftType<TGiftFieldsFragment>>[] = [];

    secs.push({
      title: 'Gift',
      topComponent: () => topComponent,
      columns: _.compact([
        {
          label: 'Type',
          render: () => (gift.type === TGiftTypes.Other ? gift.customType : getReadableGiftType(gift)) as string,
        },
        {
          label: 'Event Date',
          render: () => readableDate(gift.eventDate),
        },
        gift.recipient != null && isDependant(gift.recipient) && gift.recipient.type === TDependantType.Child
          ? {
              label: 'Age',
              render: () => gift?.event?.age?.toString(),
            }
          : undefined,
        gift.recipient != null && !isDependant(gift.recipient) && gift.type === TGiftTypes.WorkAnniversary
          ? {
              label: 'Age',
              render: () => gift?.event?.age?.toString(),
            }
          : undefined,
        gift.type === TGiftTypes.WorkAnniversary && {
          label: 'Work Anniversary Variation',
          render: () => {
            return getReadableWorkAnniversaryVariation(gift.company?.workAnniversaryGiftVariation || undefined);
          },
        },
        gift.type === TGiftTypes.WorkAnniversary &&
          gift.company?.workAnniversaryGiftVariation === TWorkAnniversaryGiftVariations.Custom && {
            label: 'Custom Work Anniversary Notes',
            render: () => gift.company?.customWorkAnniversaryNotes,
          },
        {
          label: 'Donation',
          render: () => (isDonation ? 'yes' : 'no'),
        },
      ]),
    });

    const recipientColumns: TInfoCardSectionColumnProps<TGiftType<TGiftFieldsFragment>>[] = [
      {
        label: 'Recipient Name',
        render: () => gift.recipient?.fullName,
      },
    ];

    if (gift.recipient && isDependant(gift.recipient)) {
      recipientColumns.push({
        label: 'Relationship',
        render: () => `${getReadableRelationshipType(gift?.recipient)} of ${gift.employee?.fullName}`,
      });
    }

    recipientColumns.push({
      label: 'Food Allergies',
      render: () => gift.recipient?.foodPreferences,
    });

    secs.push({
      title: 'Recipient Information',
      columns: recipientColumns,
    });

    const customizationColumns: TInfoCardSectionColumnProps<TGiftType<TGiftFieldsFragment>>[] = [];

    if (gift.type === TGiftTypes.NewHire && gift.company?.legoColor) {
      customizationColumns.push({
        label: 'Lego Color',
        render: () => {
          return (
            <div className={styles.colorIndicatorContainer}>
              {gift.company?.legoColor}
              <div
                className={styles.colorIndicator}
                style={{ backgroundColor: gift.company?.legoColor || undefined }}
              />
            </div>
          );
        },
      });
    }

    if (gift.type === TGiftTypes.NewHire && gift.company?.hasCustomOnboardBrick) {
      customizationColumns.push({
        label: 'Custom Onboard Brick',
        render: () => {
          if (gift.recipient && !isDependant(gift.recipient) && gift.recipient.customOnboardBrick != null) {
            return gift.recipient.customOnboardBrick;
          }
        },
      });
    }

    customizationColumns.push(
      {
        label: 'Tissue Color',
        render: () => {
          if (gift.company?.tissueColor) {
            return (
              <div className={styles.colorIndicatorContainer}>
                {gift.company.tissueColor}
                <div className={styles.colorIndicator} style={{ backgroundColor: gift.company.tissueColor }} />
              </div>
            );
          } else {
            return null;
          }
        },
      },
      {
        label: 'Sign As',
        render: () => gift.company?.signAs,
      },
      {
        label: 'Special Note',
        render: () => gift.company?.specialNote,
      },
    );

    secs.push({
      title: 'Customizations',
      columns: customizationColumns,
    });

    const deliveryAddress = getReadableDeliveryMethodType(gift) as string;
    secs.push({
      title: deliveryAddress ? `${deliveryAddress} Address` : 'No Delivery Method',
      columns: [
        {
          label: 'Country',
          render: () => {
            const country = getReadableAddressProperty(gift, 'country');
            if (country) {
              return <CountryName name={country} />;
            }
          },
        },
        {
          label: 'State',
          render: () => getReadableAddressProperty(gift, 'state'),
        },
        {
          label: 'City',
          render: () => getReadableAddressProperty(gift, 'city'),
        },
        {
          label: 'Address 1',
          render: () => getReadableAddressProperty(gift, 'address1'),
        },
        {
          label: 'Address 2',
          render: () => getReadableAddressProperty(gift, 'address2'),
        },
        {
          label: 'Postal Code',
          render: () => getReadableAddressProperty(gift, 'zipCode'),
        },
      ],
    });

    return secs;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    gift.company?.legoColor,
    gift.company?.signAs,
    gift.company?.specialNote,
    gift.company?.tissueColor,
    gift,
    gift?.event?.age,
    isDonation,
    gift.recipient,
    topComponent,
  ]);

  const onGiftUpdate = useCallback(
    (data: TGiftExtraAttributesForm) => {
      return updateGift({
        variables: {
          id: gift.id,
          companyId: gift.companyId,
          version: gift._version,
          input: {
            rack: data.rack == null ? null : data.rack,
            notes: data.notes == null ? null : data.notes,
          },
        },
      }).catch((error) => {
        console.error(serializeError(error));
        toast.error('There was an error updating the gift.');
      });
    },
    [gift._version, gift.companyId, gift.id, updateGift],
  );

  const onMoveToDoneEnd = useCallback(() => {
    setGiftsGoingToDone(false);
    closeModal();
  }, [closeModal]);

  const { data: allGifts } = useQueryAllGroupedAssemblingGifts();

  const movingToDoneGifts = useMemo(() => {
    return giftsGoingToDone ? allGifts.filter((g) => g.id === gift.id) : [];
  }, [allGifts, gift.id, giftsGoingToDone]);

  return (
    <>
      {giftsGoingToDone && (
        <MovingToDoneModalContent
          allGifts={allGifts}
          gifts={movingToDoneGifts}
          onCancel={onMoveToDoneEnd}
          onSuccess={onMoveToDoneEnd}
        />
      )}
      {!_.isEmpty(confirmationProps) && (
        <ConfirmationModal
          {...confirmationProps}
          closeModal={() => setConfirmationProps(undefined)}
          confirmLoading={deleteLoading || removeGiftFromGroupLoading}
          onCancelClick={() => setConfirmationProps(undefined)}
        />
      )}
      <Modal
        closeModal={closeModal}
        size='large'
        headline={
          gift.recipient?.fullName ? `Gift for ${gift.recipient?.fullName}` : getRemovedInfoFromRecipientData(gift)
        }
        supportingText={`From ${gift.company?.name ?? '-'}`}
        contentClassName={styles.modal}
        showCancelButton={false}
        showCustomButton={possibleMoveToColumns.length > 0}
        customButton={() => {
          return (
            <SplitButton
              loading={moveLoading}
              options={possibleMoveToColumns.map((c) => ({
                label: c.name,
                value: c.id,
              }))}
              dropDownAction={setMoveTo}
              buttonAction={actuallyMoveGift}
              disabled={moveLoading}
            />
          );
        }}
      >
        {!gift.company || !gift.event ? (
          <Loading />
        ) : (
          <div className={styles.giftDetailsContent}>
            <InfoCardInner<TGiftType<TGiftFieldsFragment>> data={gift} sections={sections} />
            <div>
              {gift?.type === TGiftTypes.WorkAnniversary && gift?.company?.specialNote && (
                <div className={styles.giftSpecialNotes}>
                  <span className={styles.giftSpecialNotesTitle}>Gift Special Notes</span>
                  <span className={styles.giftSpecialNotesContent}>{gift?.company?.specialNote}</span>
                </div>
              )}
              {gift?.type === TGiftTypes.NewHire && gift?.company?.swag && (
                <div className={styles.giftSpecialNotes}>
                  <span className={styles.giftSpecialNotesTitle}>New Hire Notes</span>
                  <span className={styles.giftSpecialNotesContent}>{gift?.company?.swag}</span>
                </div>
              )}
              <ControlledForm<TGiftExtraAttributesForm>
                className={styles.form}
                fields={[
                  {
                    type: 'textField',
                    label: 'Rack',
                    name: 'rack',
                  },
                  {
                    type: 'textArea',
                    label: 'Notes',
                    name: 'notes',
                  },
                ]}
                formProps={{
                  defaultValues: _.pick(gift, 'rack', 'notes'),
                  resolver: joiResolver(getUpdateGiftExtraAttributesSchema(), {
                    convert: true,
                    abortEarly: false,
                    stripUnknown: false,
                  }),
                }}
                onValidSubmit={onGiftUpdate}
              />
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export const GiftDetails = () => {
  const { companyId, giftId } = useParams();

  const {
    data: gift,
    error,
    loading,
  } = useFragmentOrFetchGift({
    id: giftId as string,
    companyId: companyId as string,
  });

  if (error) return <ErrorPage error={error} />;
  if (gift == null || loading) return <LoadingPage />;

  return <GiftDetailsInner {...gift} />;
};

export const GiftDetailsWrapper = () => {
  const {
    data: subscriptionGifts,
    error: subscriptionError,
    loading: subscriptionLoading,
  } = useQueryAllSubscriptionAssemblingGifts();
  const { data: newHireGifts, error: newHireError, loading: newHireLoading } = useQueryAllNewHireAssemblingGifts();

  if (subscriptionError || newHireError) return <ErrorPage error={subscriptionError || newHireError} />;
  if (subscriptionGifts == null || newHireGifts == null || subscriptionLoading || newHireLoading)
    return <LoadingPage />;

  return <GiftDetails />;
};
